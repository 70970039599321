@import "../bootstrap/forms";
@import "../bootstrap/input-group";

.form-check {
	.form-check-input {
		margin-top: .125em;
		width: 20px;
		height: 20px;
	}
	.form-check-label {
		@extend .ml-1;
	}
}


.form-control {
	appearance: none;
	@extend .px-3;
	@extend .py-2;
	font-size: 1.125rem;
	@extend .font-weight-bold;
	&::placeholder {
		@extend .text-secondary;
	}
}

.form-inline {
	.form-control {
		margin-right: 1rem;
	}
}

.select2-container {
    width: 100% !important;
}

.select2-container--default .select2-selection--multiple {
	appearance: none;
	border-radius: 10px !important;
	@extend .px-1;
	padding: .25rem .25rem .5rem .25rem;
	font-size: 1.125rem;
	@extend .font-weight-bold;
	&::placeholder {
		@extend .text-secondary;
	}
	.select2-selection__choice {
		border-radius: 5px;
		.select2-selection__choice__remove {
			border-radius: 0 !important;
			margin-right: 2px;
			padding: 3px 4px;
		}
	}
}

.hero-form ,
.sidebar-filter {
	label {
		display: block;
		color: $white;
		margin: 0 0 0 14px;
		padding: 0;
	}
	.form-control {
		border: none;
	}
}

select.form-control {
	cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='16' viewBox='0 0 8 16' fill='none'%3E%3Cpath d='M0 -3.49691e-07L-6.99382e-07 16L8 8' fill='%232A3B72'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: .5rem;
    background-position: calc(100% - 1rem) center;
}
