@import "common/variables";

/** Import everything from bootstrap */
@import "bootstrap/scss/bootstrap-reboot.scss";
@import "bootstrap/scss/bootstrap-grid.scss";
@import "common/utilities";

@import "includes";

header, footer {
	background-color: $secondary;
}

header {
    border-top: 5px solid $primary;
}

img {
    @extend .img-fluid;
}

footer {
    padding: 2rem 0;

    @include media-breakpoint-up(lg) {
        padding: 3.5rem 0;
    }
    .footer-inner {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 1.5rem;
        text-align: center;
        @include media-breakpoint-up(md) {

            text-align: left;
            flex-direction: row;
            justify-content: space-between;
        }
    }
    .footer-copy {
        color: white;
        margin-bottom: 0;
        @include media-breakpoint-up(lg) {
            font-size: 1.125rem;
        }
    }
    .footer-social {
        display: flex;
        gap: 1rem;
    }
}

.col-xl-5th {
    @include media-breakpoint-up(xl) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.section {
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 3.5rem;
    }
}

.section-hero {
    .hero-img {
        position: relative;
        height: 240px;
        @include media-breakpoint-up(md) {
            height: 360px;
        }
        @include media-breakpoint-up(lg) {
            height: 480px;
        }
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left:0;
            right:0;
            aspect-ratio: 144 / 5;
            background-image: url("data:image/svg+xml,%3Csvg width='1440' height='51' viewBox='0 0 1440 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1440 50.75H0V26.0892C72.1495 28.7303 198.229 44.041 346.328 38.7588C494.427 33.4765 558.986 0.75 710.885 0.75C862.784 0.75 923.544 37.3359 1075.44 38.7588C1227.34 40.1816 1364.05 14.0726 1440 7.57208V50.75Z' fill='white'/%3E%3C/svg%3E%0A");
            background-size: 101%;
            background-repeat: no-repeat;
            background-position: center;
        }
        img {
            width: 100%;
            height: 100%;
            object-position: center;
            -o-object-position: center;
            object-fit: cover;
            -o-object-fit: cover;
        }
    }
    h1 {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-weight: 400;
        line-height: 1;
        margin-top: 1rem;
        strong {
            text-transform: uppercase;
        }
    }
}

.section-checks {
    .check-item {
        background-color: $secondary;
        color: white;
        padding: 1rem;
        border-radius: 10px;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 1rem;
        @include media-breakpoint-up(md) {
            font-size: 1.125rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 1.25rem;
        }
        strong {
            line-height: 1;
            text-transform: uppercase;
        }
        img {
            @extend .flex-shrink-0;
        }
    }
}

.cta-cards {
    position: relative;
    padding-bottom: 2rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 3.5rem;
    }
    &:before {
        content: '';
        background-color: $primary;
        position: absolute;
        top: 111px;
        right:0;
        bottom: 0;
        left: 0;
    }
    .cta-card {
        border: 5px solid white;
        height: 100%;
        background-color: $secondary;
        color:white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: .75rem;
    }
    img {
        margin-bottom: 1rem;
    }
    h2 {
        font-weight: 400;
        @extend .h4;
        color:white;
        margin-bottom: 0;
    }
    .btn {
        margin-top: auto;
        width: 100%;
    }
}


.page-body {
    @extend .py-4;
    @extend .py-lg-5;
}

.page-header {
    @extend .mb-4;
    @extend .mb-lg-5;
    @extend .d-flex;
    @extend .flex-column;
    @extend .flex-lg-row;
    @extend .justify-content-lg-between;
    @extend .align-items-lg-center;
}

.header-info {
    @extend .d-flex;
    @extend .align-items-start;
    @extend .mb-4;
    @extend .mb-lg-0;

    .info-badge {
        @extend .mr-4;
        @extend .flex-shrink-0;
    }

    .info-title {
        >* {
            @extend .mb-0;
        }
        .title-h1 {
            line-height: 1;
        }
        .title-sub {
            @extend .text-secondary;
            font-size: 1.875rem;
            line-height: 1.25;
            &-2 {
                font-size: 1.5rem;
            }
        }
    }
}

.header-details {
    @include media-breakpoint-up(xl) {

        width: 50%;
        padding-left: 15px;
    }
    .detail-pills {
        display: grid;
        gap: 1rem;

        grid-template-columns: repeat(2, minmax(0, 1fr));
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        @include media-breakpoint-up(md) {
            gap: 1.5rem;
        }

        .pill-item {
            @extend .btn;
            @extend .px-2;

            &-lot {
                @extend .btn-secondary;
            }
            &-time {
                grid-column: span 2/span 2;
                text-transform: none;
                @extend .btn-white;
                @extend .btn-icon;
                @extend .btn-icon-clock;
                border: 1px solid #ddd;
                span {
                    font-weight: normal;
                    margin-right: .5em;
                }
                @include media-breakpoint-down(md) {
                    order:3
                }
            }
            &-reg {
                @extend .btn-warning;
            }
            &-favourite {
                grid-column: span 2/span 2;
                @extend .btn-secondary;
                @extend .btn-icon;
                @extend .btn-icon-star;
                @include media-breakpoint-down(md) {
                    order:4
                }
            }
        }
    }
}

.body-gallery {
    @extend .mb-4;
    .gallery-image {
        @extend .mb-4;
        img {
            @extend .rounded;
            @include media-breakpoint-down(sm) {
                border-radius: 0!important;
            }
        }
    }
    .gallery-items {
        @extend .overflow-hidden;
        @extend .d-flex;
        @extend .flex-wrap;
        @extend .rounded;
        @include media-breakpoint-down(sm) {
            border-radius: 0!important;
        }
        > * {
            flex: 1 1 33.33%;
            @include media-breakpoint-up(md) {
                flex: 1 1 0%;
            }
        }
        .item-arrow {
            @extend .bg-secondary;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z' fill='WHITE'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 1rem;
            cursor: pointer;
        }
    }
    .gallery-more {
        @extend .mt-3;
        @extend .d-flex;
        @extend .flex-wrap;
        row-gap: .75rem;
        column-gap: 1.25rem;
        .more-label {
            @extend .text-uppercase;
        }
    }
}
.details-list {
    @extend .list-unstyled;
    @extend .mb-0;
    .detail-item {
        @extend .d-flex;
        >span {
            @extend .flex-fill;
            @extend .d-flex;
            &:after {
                @extend .flex-grow-1;
                content: '';
                border-bottom: 2px dotted $secondary ;
                margin: 0 .25rem .25rem .25rem
            }
        }
        >strong {
            @extend .text-right;
        }
        &-header {
            @extend .font-weight-bold;
            >span {
                &:after {
                    border-bottom: 2px dashed $secondary ;
                    margin-bottom: .5rem;
                }
            }
        }
    }
}

.content-section {
    @extend .rounded;
    @extend .p-4;
    @extend .mb-4;
    @extend .mb-xl-5;


    .section-header {
        @extend .d-flex;
        @extend .flex-column;
        @extend .flex-md-row;
        @extend .align-items-md-center;
        @extend .justify-content-md-between;
        margin-bottom: 2rem;
        .header-title {
            @extend .mb-4;
            @extend .mb-lg-0;
            >* {
                @extend .mb-0;
            }
            .title-h1 {
                line-height: 1;
            }
            .title-sub {
                @extend .text-primary;
                font-size: 1.5rem;
                line-height: 1.25;
            }
        }
        .header-cta {
            a {
                @extend .btn;
                @extend .btn-info;
                @extend .btn-icon;
                @extend .btn-icon-right;
                @extend .btn-icon-pdf;
            }
        }
    }
    &-white {
        @extend .bg-white;
        @extend .text-secondary;
        @extend .border;
        border-width: 1px !important;
        border-bottom-width: 3px !important;
        border-color: #ddd;
    }
    &-details {
        .details-list {
            display: grid;
            column-gap: 1rem;

            @include media-breakpoint-up(xl) {

                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
    }
    &-bid {
        .alert {
            @extend .text-secondary;
        }

        .bid-details {
            @extend .d-flex;
            @extend .flex-column;
            @extend .flex-xl-row;
            @extend .justify-content-xl-between;
            @extend .align-items-xl-end;
            @extend .mt-lg-4;
            .bid-price {
                line-height: 1;
                label {
                    display: block;
                }
                strong {
                    font-size: 2rem;
                }
                span {
                    @extend .text-uppercase;
                    @extend .ml-1;
                }
            }
            .bid-time {
                @extend .ml-xl-4;
                strong {
                    @extend .text-uppercase;
                }
                img {
                    vertical-align: text-top;
                }
            }
        }

        .bid-form {
            @extend .d-flex;
            @extend .flex-column;
            @extend .flex-xl-row;
            @extend .flex-nowrap;
            @extend .align-items-start;
            @extend .align-items-xl-end;
            @extend .justify-content-xl-between;
            gap: 1rem;
            button {
                @extend .flex-shrink-0;
                @extend .btn;
                @extend .btn-secondary;
                @extend .btn-lg;
                line-height: 1.25;
            }
            .input-group {
                .input-group-text {
                    @extend .font-weight-bold;
                    font-size: 1.75rem;
                    line-height: .8;
                }
            }
        }
    }
    .section-cta {
        @extend .d-flex;
        @extend .justify-content-center;

        @extend .mt-4;
        gap: 1rem;
    }
}

.content-register {
    @extend .d-flex;
    @extend .flex-column;

    >span {
        font-size: 1.5rem;
        @extend .text-uppercase;
        @extend .text-info;
        @extend .mb-4;
        @extend .font-weight-bold;
        @extend .text-center;
    }
    >a {
        @extend .btn;
        @extend .btn-lg;
        @extend .btn-info;
        text-transform: none!important;
    }
}

.breadcrumbs-wrapper {
    @extend .bg-primary;
    @extend .py-2;
    @extend .text-secondary;
    .breadcrumbs {
        display: flex;
        gap: 1.5rem;
        font-size: 14px;
    }
}



.archive-auction, .single-auction {
    overflow-x: hidden;
    background-color: #F7F7F7;
    padding-top: 0px;
    padding-bottom: 45px;
}

.auction-header {

    @extend .py-4;
    @extend .py-xl-5;

    h1 {
        @extend .font-weight-normal;
        line-height: 1;
        font-size: 2rem;
    }

    .header-details {
        @extend .mt-4;
        @extend .mb-0;
        @extend .list-unstyled;
        @extend .d-flex;
        @extend .w-100;
        @extend .flex-column;
        @extend .flex-lg-row;
        gap: 1rem;
        div {
            @extend .d-flex;
            @extend .align-items-center;
            gap: .25rem;
            span, a {
                @extend .d-flex;
                @extend .align-items-center;
            }
            a {
                @extend .text-secondary;
                text-decoration: underline;
            }
            &.detail-download {
                @extend .font-weight-bold;
                @extend .ml-lg-auto;
            }
        }
        img {
            @extend .mr-1;
        }
    }

    &-mobile {
        .header-details {
            gap:0;
            li {
                &.detail-download {
                    @extend .mt-3;
                }
            }
        }
    }
    &:not(.auction-header-mobile) {
        @extend .d-none;
        @extend .d-lg-block;
    }
}

.auction-sidebar {
    @extend .position-relative;
    @extend .bg-secondary;
    @extend .pr-lg-4;
    @extend .py-4;
    @extend .py-xl-5;
    @extend .mb-4;
    border-bottom-right-radius: 10px;
    .sidebar-bg {
        @extend .position-absolute;
        @extend .bg-secondary;
        top:0;
        right: 100%;
        bottom: 0;
        left: -100%;
    }
    @include media-breakpoint-down(md) {
        border-bottom-right-radius: 0;
        &:after {
            content: '';
            position: absolute;
            background-color: $secondary;
            top:0;
            right: -100%;
            bottom: 0;
            left: 100%;
        }
    }
    a {
        @extend .text-white;
        @extend .text-decoration-none;
    }
}

.auction-results {
    width: 100%;
    padding-left: 300px;
    float:left;
}

.sidebar-filter {
    @extend .d-flex;
    @extend .flex-wrap;
    @extend .justify-content-between;
    @extend .align-items-center;
    @extend .align-items-lg-stretch;
    @extend .flex-lg-column;
    gap: 1rem;
    .filter-reset {
        img {
            margin-left: .5rem;
        }
    }
    .filter-toggle {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .mb-0;
        line-height: 1;
        gap: .5rem;
        @extend .d-lg-none;
        @extend .h2;
        font-weight: 500;
    }
    #filterCollapse {
        @extend .w-100;
        @extend .d-lg-block;
    }
    .filter-fields {
        @extend .d-flex;
        @extend .flex-column;

    }
    button {
        @extend .btn;
        @extend .btn-lg;
        @extend .rounded;
        &:not([type="submit"]) {
            @extend .btn-white;
            @extend .text-left;
            @extend .text-capitalize;
            span {
                @extend .font-weight-normal
            }
        }
        &[type="submit"] {
            @extend .btn-primary;
        }
    }
    .form-control {
        @extend .rounded;
    }
}

.products-sort {
    @extend .d-flex;
    @extend .align-items-center;
    @extend .mb-4;
    @extend .mb-xl-5;
    font-size: .75rem;
    @include media-breakpoint-up(md) {
        font-size: 1rem;
    }
    .sort-page {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .flex-fill;
        font-weight: 500;

        &:after {
            content: '';

            @extend .mx-4;
            @extend .d-flex;
            @extend .flex-grow-1;
            height: 1px;
            background-color: #ccc;
        }
    }
    .sort-order {
        @extend .text-secondary;
        @extend .position-relative;

        select {
            appearance: none;
            background-color: transparent;
            border: 0;
            @extend .px-4;
            @extend .position-relative;
            z-index: 20;
            cursor: pointer;
        }

        &:before, &:after {
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            @extend .position-absolute;
            top:0;
            bottom:0;
        }
        &:before {
            left: 0;
            right: auto;
            width: 1rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M183.6 469.6C177.5 476.2 169 480 160 480s-17.5-3.8-23.6-10.4l-88-96c-11.9-13-11.1-33.3 2-45.2s33.3-11.1 45.2 2L128 365.7V64c0-17.7 14.3-32 32-32s32 14.3 32 32V365.7l32.4-35.4c11.9-13 32.2-13.9 45.2-2s13.9 32.2 2 45.2l-88 96zM320 72c0-22.1 17.9-40 40-40H472c22.1 0 40 17.9 40 40V184c0 22.1-17.9 40-40 40H360c-22.1 0-40-17.9-40-40V72zM443.1 303l80 128c6.2 9.9 6.5 22.3 .9 32.5s-16.4 16.5-28 16.5H336c-11.6 0-22.3-6.3-28-16.5s-5.3-22.6 .9-32.5l80-128c5.8-9.4 16.1-15 27.1-15s21.3 5.7 27.1 15z' fill='%232E3640'/%3E%3C/svg%3E");
        }
        &:after {
            left: auto;
            right: 0;
            width: .75rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z' fill='%232E3640'/%3E%3C/svg%3E");
        }
    }
}

.products-grid {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.products-footer {
    @extend .mt-4;
    @extend .mt-xl-5;
    p {
        font-size: 18px;
    }
    .products-cta {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        a {
            @extend .rounded;
            text-transform: none;
        }
    }
}

.products-pagination {
	@extend .mt-4;
	.pagination {
		float: right;
	}
}
