@import "../bootstrap/navbar";

.navbar {
    align-items: start;
    gap: 1.5rem;

    .navbar-brand {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: .75rem;
        text-transform: uppercase;
        text-decoration: none;
        @extend .flex-shrink-1;

        @include media-breakpoint-down(lg) {
            width: 250px;
            height: auto;
        }
    }

    .navbar-toggler {

        .navbar-toggler-icon {
            width: 2.5rem;
            height: 2.5rem;
        }

        &:not(.collapsed) {
            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Crect width='40' height='40' fill='white'/%3E%3Cpath d='M32 11.4284L28.5716 8L20 16.5716L11.4284 8L8 11.4284L16.5716 20L8 28.5716L11.4284 32L20 23.4284L28.5716 32L32 28.5716L23.4284 20L32 11.4284Z' fill='%232E3640'/%3E%3C/svg%3E");
            }
        }
    }

    .navbar-collapse {
        flex-direction: column;
        gap: 2.5rem;
        align-items: stretch;
        @include media-breakpoint-down(lg) {
            margin-top: 1rem;
        }
        @include media-breakpoint-up(xl) {
            gap: 1.5rem;
        }
        &.show {
            display: flex;
        }
        @include media-breakpoint-up(xl) {
            align-items: end;
            gap: 2rem;
            flex-direction: column-reverse;
        }
    }

    .nav-item {
        @include media-breakpoint-down(lg) {
            border-top: 1px solid white;
        }
    }

    .nav-link {
        padding: .5rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        &:hover, &.current {
            text-decoration: none;
            @include media-breakpoint-up(xl) {
                background-color: $primary;
                color: $secondary !important;
                border-radius: 5px;
            }
        }
        @include media-breakpoint-down(lg) {
            padding-left: 0;
            padding-top: .75rem;
            padding-bottom: .75rem;
        }
    }

    .dropdown-menu {
        .dropdown-item {
            padding: .5rem;
            text-transform: uppercase;
        }

        @include media-breakpoint-down(lg) {
            background: none;
            border: none;
            margin: 0 0 0 1rem;
            padding:0;
            border-radius: 0;
            .dropdown-item {
                border-top: 1px solid white;
                font-weight: normal !important;
                padding-left: 0;
                padding-top: .75rem;
                padding-bottom: .75rem;
                color:white;
            }
        }
    }

    .navbar-nav {
        @include media-breakpoint-down(lg) {
            border-bottom: 1px solid white;
        }
        @include media-breakpoint-up(xl) {
            gap: 1.5rem;
        }
    }

    .navbar-cta {
        display: flex;
        gap: 1.5rem;
        @include media-breakpoint-down(lg) {
            justify-content: center;
        }
    }

    .navbar-social {

        display: flex;
        gap: 1.5rem;
        justify-content: center;
        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    .navbar-copy {
        color: white;
        font-size: .875rem;
        text-align: center;
        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}
