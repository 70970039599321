@import "../bootstrap/type";

h1, .h1 {
	@include media-breakpoint-down(lg) {
		font-size: 1.75rem;
	}
}
h2, .h2 {
	@include media-breakpoint-down(lg) {
		font-size: 1.375rem;
	}
}
h3, .h3 {
	@include media-breakpoint-down(lg) {
		font-size: 1.25rem;
	}
}
h4, .h4 {
	@include media-breakpoint-down(lg) {
		font-size: 1.125rem;
	}
}
h5, .h5 {
	@include media-breakpoint-down(lg) {
		font-size: 1rem;
	}
}
