body#login-page {

	padding-top: 40px;
	padding-bottom: 40px;
	background-color: $body-bg;
    background-color: #F7F7F7;
    background-repeat: no-repeat;
    background-size: 100% auto;

	h2 {
		font-size: 22px;
		line-height: 1.3;
	}
	
	a{
		color: $primary;
		text-decoration: underline;
		&:hover {
			color: $primary;
			text-decoration: none;
		}
	}

	@include media-breakpoint-up(md) {
		h2 {
			font-size: 26px;
		}
	}

	.small-logo {
		width:  300px;
		margin: 0 auto 60px;
		img {
			width:  225px;
			margin: 0;
			padding: 0;
		}
	}	
}

.forgot-password-form {
	margin-bottom: 60px;
}

#forgotten-password {
	padding-top: 20px;
	padding-bottom: 20px;
}

.forgot-password-heading {
	margin-top: 0;
	margin-bottom: 15px;
	font-size: 26px;
}

.account-number-input {
	margin-top: 20px;
}

.form-signin {
	padding: 30px;
	@extend .bg-secondary;
	color: #fff;
	
	.form-signin-heading ,
	h2 ,
	p ,
	a ,
	label {
		color: #FFF;
	}
	
	.form-signin-heading,
	.checkbox {
		margin-bottom: 15px;
		margin-top: 0;
	}
	input[type="login"],
	input[type="text"],
	input[type="password"] {
		width: 100%;
		margin-bottom: 15px;
	}
	margin-bottom: 30px;

	.btn {
		margin-bottom: 30px;
		background-color: $primary !important;
	}
}

.login-box {
	margin-bottom: 20px;
	overflow: hidden;
	width: 100%;
	background-color: #FFF;
	border-radius: 10px;
	float: left;
	padding: 30px;
}

.login-btn {
	margin-top: 30px;
}

@include media-breakpoint-up(md) {
	.form-signin {
		// max-width: 300px;
		// padding: 19px 29px 29px;
		margin: 0 auto;
		// padding-left: 30px;
		// background-color: #fff;
		// border: 1px solid #e5e5e5;
		-webkit-border-radius: 5px;
		   -moz-border-radius: 5px;
				border-radius: 5px;
		-webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
		   -moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
				box-shadow: 0 1px 2px rgba(0,0,0,.05);
		
		.form-signin-heading,
		.checkbox {
			margin-bottom: 15px;
			margin-top: 0;
		}
	
		input[type="text"],
		input[type="password"] {
			// width: 240px;
		}
	}

	.forgot-password-form {
		padding: 30px;
		margin-bottom: 0;
	}
}