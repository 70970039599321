// Variables

// Fonts
// Alternative to Avenir:
$font-family-sans-serif:      "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-weight-bold:            700 !default;

// Color system

$primary: 	#F9ED13;
$secondary: #2E3640;
$danger: 	#D11335;
$info: 		#EE7203;
$warning: 	#FDCC20;
$success: 	#A1C958;
$light:		#F7F7F7;
$dark:		#1E1E1E;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xxl: 1440px,
  xxxl: 1800px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1280px
);

// Body

$body-bg:		#fff;
$body-color:	$secondary;

// Links

$link-color:            $secondary;
$link-decoration:       underline;
$link-hover-decoration: none;

// Components

$border-width:      2px;
$border-radius: 	10px;
$border-radius-sm:  5px;

// Navs

$navbar-dark-color:             #fff;
$navbar-nav-link-padding-x: 	.5rem;
$navbar-padding-y: 				2rem;
$navbar-padding-x: 				0;
$navbar-toggler-padding-y:		0;
$navbar-toggler-padding-x:		0;
$navbar-toggler-border-radius:	0;
$navbar-dark-toggler-icon-bg:	url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='40' height='40' fill='white'/%3E%3Cpath d='M8 28V25.3333H32V28H8ZM8 21.3333V18.6667H32V21.3333H8ZM8 14.6667V12H32V14.6667H8Z' fill='%232E3640'/%3E%3C/svg%3E%0A");
// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg:                       $primary;
$dropdown-color:                    $secondary;


$dropdown-link-color:               $secondary;
$dropdown-link-hover-color:         #fff;
$dropdown-link-hover-bg:            $secondary;


// Cards

$card-spacer-y:                     1.75rem !default;
$card-spacer-x:                     2.25rem !default;
$card-border-color:                 $secondary;

// Buttons + Forms

$input-btn-padding-y-lg:      .675rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      1.5rem;
$input-btn-line-height:       1.5;

// Buttons

$btn-padding-y:     0.25rem;
$btn-padding-x:     1.5rem;
$btn-font-weight:	bold;

$btn-border-radius:   $border-radius-sm;
$btn-border-radius-lg:$border-radius;
$btn-font-size-lg:    1rem;


// Forms

$label-margin-bottom:		.75rem;
$input-padding-y: 			.5rem;
$input-padding-x: 			1rem;
$input-border-radius: 		$border-radius-sm;
$input-color:               $secondary;
$input-border-color:        #DDDDDD;
$input-group-addon-color:	$secondary;
$input-group-addon-bg:  	#DDDDDD;


// Typography

$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;
$h5-font-size: 1.125rem;

$headings-font-weight:	700;
$headings-color: 		$secondary;

$font-weight-bolder:    bold;

$headings-margin-bottom:  1rem;

