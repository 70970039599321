@import "../bootstrap/buttons";


.btn {
    color: $secondary;
    text-transform: uppercase;
}
.btn-secondary {
    color: white;
}
.btn-white {
    @extend .bg-white;
}
.btn-outline-primary {
    color: white;
}
.btn-black {
    background-color: black;
    color: $primary;
    &:hover {

        color: $primary;
    }
}

.btn-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    &:before {
        content: '';
        width: 1em;
        height: 1em;
        margin-right: .5rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-block;
        vertical-align: middle;
    }
}
.btn-icon-right {
    &:before {
        content: unset;
    }
    &:after {
        content: '';
        width: 1em;
        height: 1em;
        margin-left: .5rem;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        display: inline-block;
    }
}
.btn-icon-clock {
    &:before, &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136 0 12.8 10.7 7.1 96 64 20 13.3 26.6-39.9-20-13.3L280 243.2 280 120l0-24-48 0 0 24z' fill='%232E3640'/%3E%3C/svg%3E");
    }
}
.btn-icon-star {
    &:before, &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--!Font Awesome Pro 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M288.1 0l86.5 164 182.7 31.6L428 328.5 454.4 512 288.1 430.2 121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z' fill='white'/%3E%3C/svg%3E");
    }
}
.btn-icon-pdf {
    &:before, &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'%3E%3Cpath d='M3.65731 8.27273V1H16.8409L20.0228 4.18182V21H2.75003M15.4773 1V5.54545H20.0228M14.1137 17.3636V11H17.75M14.1137 14.1818H16.8409M1.84094 11H3.20458C5.02276 11 5.25003 12.1364 5.25003 12.8182C5.25003 13.5 5.02276 14.6364 3.20458 14.6364H2.06822V16.4545H1.84094V11ZM7.75003 16.4545V11H9.30003C10.3337 11 11.3864 11.4545 11.3864 13.7273C11.3864 16 10.3337 16.4545 9.30003 16.4545H7.75003Z' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
    }
}
$fa-check-svg: 'data:image/svg+xml,%3Csvg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"%3E%3Cpath fill="white" d="M173.898 439.404l-166.4-166.4c-12.497-12.497-12.497-32.759 0-45.256l45.255-45.255c12.497-12.497 32.759-12.497 45.256 0L192 312.69 413.898 90.804c12.497-12.497 32.759-12.497 45.256 0l45.255 45.255c12.497 12.497 12.497 32.759 0 45.256l-304 304c-12.497 12.497-32.759 12.497-45.256 0z"%3E%3C/path%3E%3C/svg%3E';
.btn-icon-check {
	&:before, &:after {
		background-image: url($fa-check-svg);
	}
}

.toggle-watchlist-item {
	
	.active-text {
		display: none !important;
	}  
	
	.icon {
		display: none !important;
	
		&.active {
			display: inline-block !important;
		}
	}
	
	&.toggle-watchlist-item-on {
		@extend .btn-icon-check;
		&.btn {
			background-color: $secondary;
			border-color: $secondary;
		}
		.inactive-text {
			display: none !important;
		}  
		.active-text {
			display: inline !important;
		}     	  
	}
  
}
