@import "../bootstrap/card";

.card {
    position: relative;
    height: 100%;
    .card-title {
        @extend .h3;
        text-transform: uppercase;
    }
    .card-body {
        p:last-of-type {
            margin-bottom: 0;
        }
    }

    &.card-lg {
        .card-title {
            @extend .h2;
            display: flex;
            align-items: center;
        }
        .card-body {
            p {
                font-size: 1.125rem;
            }
        }
    }
}

.card-icon {
    position: absolute;
    bottom: 1.75rem;
    right:2.25rem;
}

.card-horizontal {
    @include media-breakpoint-up(md) {

        display: flex;
        flex-direction: row;
        overflow: hidden;

        .card-img-left {
            width: 33.33333%;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-position: center;
                -o-object-position: center;
                object-fit: cover;
                -o-object-fit: cover;
            }
        }
    }
}

.product-card {
    border: none;
    filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.25));
    .card-title-wrap {
        @extend .d-flex;
        @extend .align-items-center;
        @extend .mb-4;
        @extend .justify-content-between;
        gap: 1.5rem;
    }
    .card-title {
        @extend .h5;
        @extend .mb-0;
    }
    .card-lot {
        @extend .btn;
        @extend .btn-secondary;
        text-transform: none !important;
    }

    .cta-details {
        @extend .d-block;
        @extend .mt-4;
        @extend .font-weight-bold;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
